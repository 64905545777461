<template>
  <div class="privacy-policy">

    <div class="privacy-policy__wrapper">

      <div class="head-content">
        <div class="privacy-policy__title">隐私政策</div>

        <div class="privacy-policy__date">最后更新：2022年10月05日</div>
      </div>

      <div class="privacy-policy__scroll-wrapper">
        <div class="privacy-policy__step">
          <div class="privacy-policy__step-title">1. 简介</div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>1.1.</span>
              我们是有限责任公司“INCHKIEV”。我们在乌克兰注册，注册号码为33599082，位于乌克兰基辅Yevhen
              Konovalets街29号，邮编01133。在本隐私政策中，我们使用“我们”、“我们的”和“我们”指代我们的有限责任公司。
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>1.2.</span> 当您访问我们的网站 <a class="cursor-hover"
                                                                                             href="https://www.inchkiev.ua">https://www.inchkiev.ua</a>
              (以下简称“INCHKIEV网站”) 时，我们会处理您的个人数据。
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>1.3.</span>
              当您访问INCHKIEV网站时，即表示您同意本隐私政策，并同意进行个人数据处理。您对于个人数据处理的同意完全是自愿的。
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>1.4.</span>
              根据乌克兰《个人数据保护法》第2297-IV号法律于2010年6月1日颁布，我们被定义为“控制者/个人数据控制者”。这意味着在与我们进行通信时，我们可能会收集关于您的个人信息，并确定如何使用这些个人信息。在本政策中，我们定义了我们如何处理个人数据，以及通过INCHKIEV网站使用您的个人数据的权利和选项。
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>1.5.</span>
              当我们作为个人数据控制者处理您的个人数据时，本政策适用。我们有义务保护您的机密性。我们尊重并确保任何您与我们共享或我们从其他组织处接收的个人信息的完整性。
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>1.6.</span> 本隐私政策包含有关您个人隐私权利的重要信息。请仔细阅读，了解我们如何使用您的个人数据。向我们提供您的个人数据是自愿的。但是，如果您不向我们提供您的个人数据，您使用我们的服务或与我们的互动可能会受到干扰。
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1 bottom"><span>1.7.</span> 我们收集关于您的个人数据：
            </div>
            <div class="privacy-policy__text level2"><span>1.7.1.</span> 当您直接向我们提供时
            </div>
            <div class="privacy-policy__text level2">例如，当您通过网页查询、电子邮件、电话、信函或社交媒体与我们进行沟通时，通过我们的网站发送的个人信息。
            </div>
            <div class="privacy-policy__text level2"><span>1.7.2.</span> 当我们间接获得它们时
            </div>
            <div class="privacy-policy__text level2">
              例如，您的个人信息可能通过第三方向我们提供，包括我们的业务合作伙伴、技术、支付和交付服务的分包商。在间接获得机密信息的情况下，我们将在收到个人数据时通知您，并告知您我们打算如何使用这些个人数据的方式和原因。
            </div>
            <div class="privacy-policy__text level2"><span>1.7.3.</span> 当个人数据公开可用时
            </div>
            <div class="privacy-policy__text level2">
              您的个人信息可能会从外部公共来源提供给我们。例如，根据您的社交媒体隐私设置，我们可以从这些账户或服务访问信息 (例如，当您选择通过Facebook、LinkedIn、WhatsApp或Twitter等平台与我们互动时)。
            </div>
            <div class="privacy-policy__text level2"><span>1.7.4.</span> 当您访问我们的网站时，我们会自动收集以下类型的个人信息：
            </div>
            <div class="privacy-policy__text level2"><span>(a)</span> 技术信息，包括用于连接您的设备到互联网的Internet
              Protocol（IP）地址、浏览器类型和版本、时区设置、网页查看器类型和版本、操作系统和平台。
            </div>
            <div class="privacy-policy__text level2"><span>(b)</span>
              有关您访问网站的信息，包括单击分析以查找资源（URL）到、在整个网站内和从网站中的信息 (包括日期和时间)、您查看或搜索的服务、页面响应时间、加载错误、特定页面的访问持续时间、引荐来源、页面交互信息 (例如滚动和点击)，以及查看页面所使用的方法。使用数据的来源是我们的分析跟踪系统。
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>1.8.</span> 我们可能将来自这些不同来源的个人数据结合起来，以实现隐私政策中所述的目的。
            </div>
          </div>
        </div>
        <div class="privacy-policy__step">
          <div class="privacy-policy__step-title">2. 我们使用哪些个人数据？</div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1 bottom"><span>2.1.</span> 我们可能会收集、存储和处理以下类型的个人数据：
            </div>
            <div class="privacy-policy__text level2"><span>(a)</span> 您的姓名和联系方式，包括电子邮件地址和个人社交媒体页面；
            </div>
            <div class="privacy-policy__text level2"><span>(b)</span> 帐户数据，可能包括您的姓名、电子邮件地址和位置；
            </div>
            <div class="privacy-policy__text level2"><span>(c)</span>
              我们可能会处理您发送给我们的任何消息中包含的信息 (或“通信数据”)。通信数据可能包括与通信相关的通信内容和元数据。我们的网站将生成通过网站联系表单创建的消息的元数据；
            </div>
            <div class="privacy-policy__text level2"><span>(d)</span> 您的出生日期和性别；
            </div>
            <div class="privacy-policy__text level2"><span>(e)</span>您的财务信息，例如银行详细信息和/或信用卡/借记卡详细信息、账户持有人姓名、分类代码和账号；
            </div>
            <div class="privacy-policy__text level2"><span>(f)</span> 关于您的计算机/移动设备以及您访问和使用本网站的信息，包括例如您的IP地址和地理位置；
            </div>
            <div class="privacy-policy__text level2"><span>(g)</span> 关于我们的服务，您使用/我们认为可能对您感兴趣的服务的信息；和/或
            </div>
            <div class="privacy-policy__text level2"><span>(h)</span> 任何其他您希望与我们分享的个人数据。
            </div>
          </div>
        </div>
        <div class="privacy-policy__step">
          <div class="privacy-policy__step-title">3. 我们是否处理特殊类别的数据？</div>

          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>3.1.</span>
              乌克兰的法律将某些类别的个人数据视为机密 (例如，有关您的健康状况、种族和政治观点的信息)，因此需要更多的保护;《乌克兰个人数据保护法》规定了有关个人数据的收集、处理和分析的法律规定。
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>3.2.</span>
              在某些情况下，如果您直接向我们披露这些特殊类别的数据，例如通过电子邮件，我们可能会收集和/或使用这些特殊类别的数据。我们仅在存在有效的理由并且根据乌克兰现行法律允许时，才会处理这些特殊类别的数据。
            </div>
          </div>
        </div>
        <div class="privacy-policy__step">
          <div class="privacy-policy__step-title">4. 为什么我们会使用你的个人信息？</div>

          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1 bottom"><span>4.1.</span>
              按照本政策规定，你提供给我们的个人数据将被用于以下目的。具体而言，我们可能会使用你的个人信息：
            </div>
            <div class="privacy-policy__text level2"><span>(a)</span> 为你提供所请求的服务、产品或信息；
            </div>
            <div class="privacy-policy__text level2"><span>(b)</span>
              在需要时，向你提供有关我们工作、服务、活动或产品的补充信息，只有在你已经同意接收这些信息的情况下 (如果法律要求我们获得你的同意)，例如当你注册接收我们的电子邮件新闻通讯和/或通知时；
            </div>
            <div class="privacy-policy__text level2"><span>(c)</span>
              处理你的帐户数据以实现我们网站的运作、提供我们的服务、保护我们网站和服务的安全、支持备份我们的数据库以及与你沟通；
            </div>
            <div class="privacy-policy__text level2"><span>(d)</span> 与你沟通并回答你的问题/查询；
            </div>
            <div class="privacy-policy__text level2"><span>(e)</span> 记录信息；</div>
            <div class="privacy-policy__text level2"><span>(f)</span> 管理我们与网络、利益相关者以及订购我们的服务和出版物的人之间的关系；
            </div>
            <div class="privacy-policy__text level2"><span>(g)</span> 为实现普遍的组织目标做出贡献；
            </div>
            <div class="privacy-policy__text level2"><span>(h)</span> 分析和改进我们的工作、服务、活动、产品或信息（包括我们的网站）或我们的内部记录；
            </div>
            <div class="privacy-policy__text level2"><span>(i)</span> 报告我们的工作影响和有效性；
            </div>
            <div class="privacy-policy__text level2"><span>(j)</span> 运行/管理我们的网站，确保其安全，并为你和你的设备提供最有效的内容；
            </div>
            <div class="privacy-policy__text level2"><span>(k)</span> 注册并管理你参加活动的情况；
            </div>
            <div class="privacy-policy__text level2"><span>(l)</span> 处理你通过我们的空缺职位页面申请工作或志愿服务的申请；
            </div>
            <div class="privacy-policy__text level2"><span>(m)</span> 用于培训和/或质量控制；
            </div>
            <div class="privacy-policy__text level2"><span>(n)</span> 检查和/或管理我们的账户；
            </div>
            <div class="privacy-policy__text level2"><span>(o)</span>
              履行我们的法律义务，例如与我们可能合作的监管机构、政府和/或执法机构有关的义务 (例如税务或反洗钱要求)；
            </div>
            <div class="privacy-policy__text level2"><span>(p)</span> 防止欺诈或滥用服务；和/或建立保护和/或诉讼索赔的安全。
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1 bottom"><span>4.2.</span> 市场营销沟通
            </div>
            <div class="privacy-policy__text level2"><span>4.2.1.</span>
              我们可能使用您的联系方式向您提供有关我们的工作、活动、服务和/或出版物的信息，我们认为这些信息可能会引起您的兴趣。
            </div>
            <div class="privacy-policy__text level2"><span>4.2.2.</span>
              如果通过电子邮件、短信或电话进行沟通，除非违反乌克兰现行法律，否则我们不会未经您事先同意使用您的个人数据。
            </div>
            <div class="privacy-policy__text level2"><span>4.2.3.</span> 如果您之前已经同意，但不希望将来被联系了解我们的项目和/或服务，请通过电子邮件
              <a class="cursor-hover" href="mailto:agency@inchkiev.ua">agency@inchkiev.ua.</a>
              通知我们。您随时可以通过在电子邮件底部点击退订链接来拒绝接收我们的邮件。此外，根据《乌克兰个人数据保护法》第8条第11款，您有权通过电子邮件向<a
                  class="cursor-hover" href="mailto:agency@inchkiev.ua">agency@inchkiev.ua.</a> 发送申请撤回您的个人数据处理同意。
            </div>
          </div>
        </div>
        <div class="privacy-policy__step">
          <div class="privacy-policy__step-title">5. 我们处理个人数据的法律依据</div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level2">a) 个人数据主体同意处理其个人数据；
            </div>
            <div class="privacy-policy__text level2">b) 仅为行使其权力，根据法律授权处理个人数据的个人数据所有者的许可；
            </div>
            <div class="privacy-policy__text level2">c) 对于个人数据主体是当事人或为其利益或在其要求下为达成交易而进行交易的执行和履行，或者在交易前采取的措施；
            </div>
            <div class="privacy-policy__text level2">d) 保护个人数据主体的生命利益；
            </div>
            <div class="privacy-policy__text level2">e) 履行法律规定的个人数据所有者的义务；
            </div>
            <div class="privacy-policy__text level2">f)
              保护个人数据所有者或个人数据被转移的第三方的合法利益的需要，但当保护个人数据主体在其数据处理方面的基本权利和自由的需要超过该利益时，不得这样做。
            </div>
          </div>
        </div>
        <div class="privacy-policy__step">
          <div class="privacy-policy__step-title">6. 分享您的个人数据</div>

          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>6.1.</span>
              我们不会将您的个人数据出售、出租或出借给第三方进行营销。但是，我们通常会向选定的第三方披露您的个人数据，以实现本政策所述的目的。
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>6.2.</span>
              我们可能会向专业顾问披露您的个人信息，以在合理必要的范围内获取专业建议，或建立、执行或捍卫法律权利，无论是在诉讼、行政或非诉讼程序中。我们也可能向供应商和分包商披露您的个人信息，以履行LLC
              INCHKIEV与他们所签订的任何合同，例如IT服务提供商，即网站主机、云存储服务提供商和电子邮件客户端，向代收或处理我们的付款的金融公司披露您的个人信息；仅在您直接允许的情况下向社交媒体平台披露您的个人信息；向税务机关等监管机构、广告商和广告网络，以及搜索引擎分析和提供商披露您的个人信息。
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>6.3.</span>
              除本节中具体披露的个人信息之外，当这样的披露是为了遵守法律义务或保护您或其他个人的重大利益时，我们也可能披露您的个人数据。当建立、执行或保护法律权利时，我们也可能在法庭或行政或非诉讼程序中披露您的个人信息
            </div>
          </div>
        </div>
        <div class="privacy-policy__step">
          <div class="privacy-policy__step-title">7. 将您的个人数据转移至国际
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>7.1.</span> 本节提供了有关您的个人数据可能被转移到乌克兰以外地点的情况的信息。
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>7.2.</span>
              因为我们的工作是国际性的，我们在世界各地都设有设施，因为我们使用代理机构和/或提供者代表我们处理个人信息，所以我们从您那里收集的个人信息可能会被转移到和存储在乌克兰以外的地点。
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>7.3.</span>
              与涉及个人数据的外国关系主体的合作受乌克兰宪法, ⟪乌克兰个人数据保护法⟫, 其他法规和乌克兰缔结的国际条约的规定的约束。
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>7.4.</span> 如果乌克兰议会批准的国际条约规定的规则不同于乌克兰法律规定的规则，则适用乌克兰的国际条约规则。
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>7.5.</span>
              将个人数据转移给涉及个人数据的外国关系主体仅在符合法律或乌克兰国际条约规定的情况下，如果相关国家提供了足够的个人数据保护。
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>7.6.</span>
              欧洲经济区成员国以及签署欧洲理事会关于个人数据自动处理保护的公约的国家被认为提供了足够的个人数据保护。乌克兰内阁确定确保个人数据适当保护的国家列表。个人数据不得用于收集之外的任何其他目的
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1 bottom"><span>7.7.</span> 在以下情况下，个人数据可能会被转移给涉及个人数据的外国关系主体：
            </div>
            <div class="privacy-policy__text level2"><span>7.7.1.</span> 个人数据主体明确同意此转移；
            </div>
            <div class="privacy-policy__text level2"><span>7.7.2.</span> 有必要在涉及个人数据的第三方与个人数据所有者之间缔结或执行有利于个人数据主体的交易；
            </div>
            <div class="privacy-policy__text level2"><span>7.7.3.</span> 有必要保护个人数据主体的生命利益；
            </div>
            <div class="privacy-policy__text level2"><span>7.7.4.</span> 有必要保护公共利益、建立、实施和确保法律要求；
            </div>
            <div class="privacy-policy__text level2"><span>7.7.5.</span> 个人数据所有者提供适当的保证，不干扰个人数据主体的个人和家庭生活。
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>7.8.</span> 我们网站的托管位于爱尔兰。
            </div>
          </div>
        </div>
        <div class="privacy-policy__step">
          <div class="privacy-policy__step-title">8. 存储和删除个人数据</div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>8.1.</span> 本节旨在规定LLC
              INCHKIEV的数据保留政策和程序，以确保遵守我们的法律义务来存储和删除个人数据。
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>8.2.</span> 我们处理任何目的的个人数据不应该存储时间长于这些目的所需的时间。
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1 bottom"><span>8.3.</span> 我们将按照以下方式存储您的个人数据：
            </div>
            <div class="privacy-policy__text level2"><span>(а)</span> 个人数据将被存储最少 [0] 年和最多 [7] 年。
            </div>
            <div class="privacy-policy__text level2"><span>(b)</span>
              当存储是为了遵守我们所受制约的法律义务或保护您的生命利益或他人的生命利益时，我们可能会存储您的个人数据。
            </div>
            <div class="privacy-policy__text level2"><span>(c)</span>
              如果您要求不与我们联系，我们将在默认列表中保留关于您的基本信息，以满足您的请求并避免在将来向您发送未经请求的材料。
            </div>
          </div>
        </div>
        <div class="privacy-policy__step">
          <div class="privacy-policy__step-title">9. 个人数据的安全性 / 存储和访问
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>9.1.</span>
              我们有责任保护您的个人信息的安全性，我们采用适当和比例合理的安全政策以及组织和技术措施来保护您的个人数据。
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>9.2.</span>
              您的个人信息仅可由经过适当培训的工作人员、志愿者和承包商访问，并存储在具有防止未经授权访问功能的安全服务器上。
            </div>
          </div>
        </div>
        <div class="privacy-policy__step">
          <div class="privacy-policy__step-title">10. 修改</div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>10.1.</span> 我们可能会不时更新本通知，并在我们的网站上发布新版本。
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>10.2.</span> 您应定期查看此页面，以确保您满意本政策的任何更改。
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>10.3.</span>
              我们将通过电子邮件通知您本政策的重大更改 (如可能)。如果我们没有您的联系方式，我们将在网站上发布消息来通知您任何更改 (如可能)。
            </div>
          </div>
        </div>
        <div class="privacy-policy__step">
          <div class="privacy-policy__step-title">11. 您的权利</div>
          <div class="privacy-policy__description">本节中，我们概述了您在数据保护法下所享有的权利。某些权利较为复杂，只有在特定情况下才能行使，如果您需要更多信息，请通过
            <a class="cursor-hover"
               href="mailto:agency@inchkiev.ua">agency@inchkiev.ua.</a><br>联系我们。

            根据乌克兰个人数据保护法，您的基本权利如下： <br><br></div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>11.1.</span>
              了解个人数据收集的来源、存储位置、处理目的、个人数据所有人或控制者的住所或居住地 (停留地)，或向授权人员提供相应指示以获取该信息，但法律规定的情况除外；
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>11.2.</span> 接收关于提供个人数据访问权限的条件的信息，特别是有关转让其个人数据给第三方的信息；
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>11.3.</span> 访问自己的个人数据；
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>11.4.</span>
              自收到请求之日起不迟于三十个日历日内（法律规定的情况除外）收到答复，了解个人数据是否被处理，以及获取此类个人数据的内容；
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>11.5.</span> 向个人数据所有人提出有理由的请求，反对处理自己的个人数据；
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>11.6.</span>
              向任何个人数据所有人和控制者提出有理由的请求，更改或销毁自己的个人数据，如果这些数据被非法处理或不准确；
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>11.7.</span>
              保护自己的个人数据免受非法处理和意外丢失、破坏、故意隐瞒、不提供或延迟提供，以及免受提供不准确或有损个人荣誉、尊严和商业信誉的信息的影响；
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>11.8.</span> 就个人数据处理提出投诉；
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>11.9.</span> 在个人数据保护法规定的情况下采取法律救济措施；
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>11.10.</span> 在同意过程中对限制处理自己的个人数据的权利表示保留意见；
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>11.11.</span> 撤回对个人数据处理的同意；
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>11.12.</span> 了解个人数据自动处理的机制；
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>11.13.</span> 对具有法律后果的自动化决策的保护。
            </div>
          </div>
          <div class="privacy-policy__description">关于您的在线隐私，还有什么需要了解的吗？ <br><br>我们不会在网站上故意收集儿童的个人数据。如果您未满18岁且需要向我们提供个人信息，请勿通过我们的网站发送您的信息。您可以通过直接致电或书信联系我们，联系方式可以在我们的主页上找到。您必须年满18岁才能获得我们使用此网站的许可。我们不会故意收集、使用或披露18岁以下访问者的个人信息。
            <br><br>您还应该知道，当您自愿在我们网站或社交媒体帐户的公共区域披露个人信息（例如姓名、电子地址等）时，此信息连同您在信息中披露的任何信息，可能会被第三方收集和使用，从而导致第三方发送不需要的信息。这些活动超出了我们的控制范围，本政策不适用于此类信息。在本网站的聊天或其他公共区域中发表的任何材料，均应视为已向所有第三方公开。如果您不希望您的评论被外部人士查看，建议您不要发表任何言论。最终，您将完全负责保持密码和/或帐户信息的保密性。
          </div>
        </div>
        <div class="privacy-policy__step">
          <div class="privacy-policy__step-title">12. 如何联系我们</div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>12.1.</span> 您可以通过以下方式联系我们：</div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>(a)</span> 发送邮件至上述邮政地址；
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>(b)</span> 使用我们网站上的联系表格；以及
            </div>
          </div>
          <div class="privacy-policy__item">
            <div class="privacy-policy__text level1"><span>(c)</span> 发送电子邮件至 <a
                class="cursor-hover" href="mailto:agency@inchkiev.ua">agency@inchkiev.ua.</a></div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "privacyPolicy"
}
</script>

<style scoped lang="scss">
.privacy-policy {
  min-height: calc((var(--vh, 1vh) * 100));
  display: flex;
  flex-direction: column;
  background: white;
  padding: 0 20px;

  .head-content {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 80px;
  }

  &__wrapper {
    max-width: 730px;
    margin: 0 auto;
    width: 100%;
  }

  &__page-wrapper {
    display: flex;
    flex-direction: column;

    a {
      user-select: none;
      text-align: left;
      color: black;

      font-family: 'Montserrat';
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 33px;

      &.router-link-active {
        padding-left: 42px;
        position: relative;
        font-family: 'Montserrat';
        font-weight: 600;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;


          width: 33px;
          height: 2px;
          background: #29F6D9;

          margin: auto 0;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    color: black;
    display: inline-block;

    text-align: left;
    font-family: "TimesNewRoman-I";
    font-style: italic;

    font-weight: 400;
    font-size: 80px;
    line-height: 80px;

    padding: 0 0 8px 0;
    overflow: hidden;

    &.active {


      @media screen and (max-width: 1024px) {
        font-size: 60px;
        line-height: 60px;
      }

      @media screen and (max-width: 515px) {
        font-size: 42px;
        line-height: 42px;
      }
      @media screen and (max-width: 389px) {
        font-size: 38px;
        line-height: 38px;
      }
      @media screen and (max-width: 360px) {
        font-size: 36px;
        line-height: 36px;
      }
    }

    @media screen and (max-width: 900px) {
      font-size: 60px;
      line-height: 60px;
    }

    @media screen and (max-width: 425px) {
      font-size: 44px;
      line-height: 44px;
    }
  }

  &__date {
    color: #92a1ae;

    text-align: left;

    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.02em;
    margin-top: 10px;

    @media screen and (max-width: 900px) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__scroll-wrapper {

    margin-top: 52px;

    @media screen and (max-width: 900px) {
      margin-top: 30px;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 20px;
      height: 100%;

      @media screen and (max-width: 900px) {
        width: 10px;
      }
      @media screen and (max-width: 425px) {
        width: 5px;
      }
    }


    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #D4DADF;
      padding-right: 4px;
    }
  }

  &__step {
    margin-bottom: 47px;

    @media screen and (max-width: 900px) {
      margin-bottom: 40px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__step-title {
    text-align: left;
    color: black;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 20px;
    @media screen and (max-width: 540px) {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }

  &__text {
    color: black;
    text-align: left;

    font-family: "Montserrat";
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;

    a {
      border-bottom: 1px solid black;
      padding-bottom: 1px;
      // text-decoration: underline;
    }

    span {
      font-family: 'Montserrat';
      font-weight: 600;
    }

    &.level1 {
      margin-bottom: 20px;

      &.bottom {
        margin-bottom: 8px;
      }
    }

    &.level2 {
      margin-bottom: 20px;
      padding-left: 32px;
    }
  }

  &__description {
    text-align: left;
    font-family: 'Montserrat';

    font-weight: 300;
    font-size: 20px;
    line-height: 28px;

    color: black;

    a {
      border-bottom: 1px solid black;
      padding-bottom: 1px;
    }
  }

  &__drop-list {
    display: none;
  }

  @media screen and (max-width: 920px) {

    .head-content {
      padding-top: 48px;
    }

    &__scroll-wrapper {
      height: auto;
    }

    &__wrapper {
      max-width: 100%;
      width: 100%;
      margin-left: 0;
    }

    &__drop-list {
      display: flex;
    }

    &__page-wrapper {
      display: none;
    }
  }


}
</style>