<template>
  <div class="home">
    <object
      data="INCH360_VR_platform_presentation_EN.pdf"
      type="application/pdf"
    >
      <iframe
        src="/INCH360_VR_platform_presentation_EN.pdf"
        width="100%"
        height="100%"
        style="border: none;"
      >
        <p>
          Your browser does not support PDFs.
          <a href="/INCH360_VR_platform_presentation_EN.pdf">Download the PDF</a>
          .
        </p>
      </iframe>
    </object>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  mounted() {
    window.location = "/INCH360_VR_platform_presentation_EN.pdf"
  }
}
</script>

<style lang="scss" scoped>
object {
  height: 100vh;
  width: 100%;
}
</style>
