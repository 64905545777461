import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import privacyPolicy from '../views/privacyPolicy.vue'
import PageNotFound from '../views/PageNotFound.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
    },
    {
        path: '/privacy-policy/chinese',
        name: 'privacy-policy',
        component: privacyPolicy
    },
    { path: '/:pathMatch(.*)*', component: PageNotFound },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
