<template>
  <div class="PageNotFound">404</div>
</template>

<script>
export default {
  name: "PageNotFound"
}
</script>

<style scoped lang="scss">

</style>