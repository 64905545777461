<template>
    <router-view/>
</template>
<script>
export default {
  mounted() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('&#45;&#45;vh', `${vh}px`);
  }
}
</script>


<style lang="scss">
@import "@/assets/style/_reset.scss";
@import "@/assets/style/_fonts.scss";

* {
  padding: 0;
  margin: 0;
}
</style>